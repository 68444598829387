
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import BANCard from "@/shared/components/cards/BANCard.vue";
import BlockCard from "@/shared/components/cards/BlockCard.vue";
import GhostListHeader from "@/shared/components/layouts/GhostListHeader.vue";
import PageHeaderTitle from "@/shared/components/typography/PageHeaderTitle.vue";
import { useQuery } from "@vue/apollo-composable";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useGetBlockList } from "@/shared/composables/api/useGetBlockList";
import List from "@/shared/components/lists/List.vue";
import { epochDetailsGql } from "@/api/epochs/epochDetails";
import {
  Epoch,
  EpochVariables,
  Epoch_epoch_Epoch,
} from "@/api/epochs/__generated__/Epoch";
import routeNames from "../router/routeNames";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const route = useRoute();
    const epochNum = computed(() => route?.params?.hash);
    const epochDetails = useQuery<Epoch, EpochVariables>(
      epochDetailsGql,
      () => ({
        epochNo: parseInt(epochNum.value as string),
      }),
      () => ({
        enabled: !!epochNum.value,
      })
    );
    const parsedGqlEpochDetails = computed(() =>
      parseGqlResponse<Epoch_epoch_Epoch>("Epoch", epochDetails?.result?.value)
    );
    const isLoading = computed(() => epochDetails.loading);

    const {
      mappedDataForDisplay: blockListForDisplay,
      showMore,
      showMoreIsLoading,
      isLoading: isListLoading,
    } = useGetBlockList(parseInt(epochNum.value as string));

    const gotoBlockDetails = (hash) => ({
      name: routeNames.blockDetails,
      params: { hash },
    });

    const cardDetails = computed(() => {
      return [
        {
          cardTitle: t("blocks"),
          cardAmount: parsedGqlEpochDetails?.value?.data?.blocksCount,
        },
        {
          cardTitle: t("delegators"),
          cardAmount: parsedGqlEpochDetails?.value?.data?.delegatorsCount,
        },
        {
          cardTitle: t("pools"),
          cardAmount: parsedGqlEpochDetails?.value?.data?.poolsCount,
        },
      ];
    });

    return {
      t,
      cardDetails,
      blockListForDisplay: blockListForDisplay,
      showMoreIsLoading,
      showMore,
      gotoBlockDetails,
      parsedGqlEpochDetails,
      isListLoading,
      isLoading,
    };
  },
  components: {
    BANCard,
    BlockCard,
    GhostListHeader,
    PageHeaderTitle,
    List,
  },
});
