import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1322acfa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center page-header-title" }
const _hoisted_2 = { class: "page-header-title__header-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_skeleton, {
      active: "",
      paragraph: { rows: 0 },
      loading: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]),
      _: 3
    }, 8, ["loading"])
  ]))
}