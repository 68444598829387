import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-751efb5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ban-card-outline" }
const _hoisted_2 = { class: "ban-card__icon mt-10" }
const _hoisted_3 = {
  key: 0,
  class: "h-100"
}
const _hoisted_4 = { class: "ban-card__amount text-cut" }
const _hoisted_5 = { class: "ban-card__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockOutlined = _resolveComponent("BlockOutlined")!
  const _component_AdaCurrencySymbol = _resolveComponent("AdaCurrencySymbol")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_AmountText = _resolveComponent("AmountText")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_card, { class: "ban-card" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_skeleton, {
          active: "",
          paragraph: { rows: 1 },
          loading: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  span: 4,
                  class: "mr-20"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      (_ctx.unitType === 'blocks')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_BlockOutlined, { class: "h-100 color-primary" })
                          ]))
                        : (_openBlock(), _createBlock(_component_AdaCurrencySymbol, {
                            key: 1,
                            class: "color-primary"
                          }))
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: "flex",
                  class: "text-cut-flex"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      (_ctx.cardDetails?.cardAmount)
                        ? (_openBlock(), _createBlock(_component_AmountText, {
                            key: 0,
                            class: "ban-card__amount text-cut d-inline",
                            amount: _ctx.cardDetails?.cardAmount,
                            minPrecision: _ctx.minPrecision
                          }, null, 8, ["amount", "minPrecision"]))
                        : _createCommentVNode("", true),
                      (_ctx.cardDetails.slotName)
                        ? _renderSlot(_ctx.$slots, _ctx.cardDetails.slotName, { key: 1 }, undefined, true)
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.cardDetails.cardTitle), 1)
                  ]),
                  _: 3
                })
              ]),
              _: 3
            })
          ]),
          _: 3
        }, 8, ["loading"])
      ]),
      _: 3
    })
  ]))
}