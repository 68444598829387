import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "show-more" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_ArrowRightOutlined = _resolveComponent("ArrowRightOutlined")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_button, _mergeProps({ class: "show-more-button" }, _ctx.$attrs), {
      default: _withCtx(() => [
        (!_ctx.$attrs.loading)
          ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 20 }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default", {}, () => [
                      _createTextVNode(_toDisplayString(_ctx.t("Show More")), 1)
                    ])
                  ]),
                  _: 3
                }),
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ArrowRightOutlined)
                  ]),
                  _: 1
                })
              ]),
              _: 3
            }))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 16)
  ]))
}