import gql from "graphql-tag";

export const epochDetailsGql = gql`
  query Epoch($epochNo: Int!) {
    epoch(epochNo: $epochNo) {
      ... on Epoch {
        id
        epochNo
        startTimestamp
        endTimestamp
        blocksCount
        transactionsCount
        transactionsAmount
        transactionsFee
        poolsCount
        registeredPoolsCount
        poolsWithStakeCount
        poolsWithBlocksCount
        delegationAmount
        rewardsAmount
        poolsFee
        accountsCount
        delegatorsCount
        rewardsCount
        activePoolsCount
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
