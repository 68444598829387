import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e0b30b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeaderTitle = _resolveComponent("PageHeaderTitle")!
  const _component_BANCard = _resolveComponent("BANCard")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_GhostListHeader = _resolveComponent("GhostListHeader")!
  const _component_BlockCard = _resolveComponent("BlockCard")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_List = _resolveComponent("List")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeaderTitle, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Epoch Details")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, {
      justify: "center",
      align: "middle",
      wrap: "",
      gutter: { lg: 16 }
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardDetails, (item) => {
          return (_openBlock(), _createBlock(_component_a_col, {
            lg: 8,
            xs: 22,
            key: item
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BANCard, {
                class: "mb-20",
                cardDetails: item,
                loading: _ctx.isLoading.value,
                "unit-type": "blocks"
              }, null, 8, ["cardDetails", "loading"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_GhostListHeader, null, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Latest Blocks")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, { class: "ant-row ant-row-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_List, {
          class: "mb-10",
          "total-items": _ctx.blockListForDisplay.length,
          "data-source": _ctx.blockListForDisplay,
          loadMore: _ctx.showMore,
          "is-show-more-loading": _ctx.showMoreIsLoading,
          "is-loading": _ctx.isListLoading
        }, {
          renderItem: _withCtx(({ item }) => [
            _createVNode(_component_a_list_item, { class: "pb-4 pt-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: _ctx.gotoBlockDetails(item.transactionId)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BlockCard, {
                      class: "mt-24",
                      blockCardDetails: item,
                      onClick: ($event: any) => (_ctx.gotoBlockDetails(item.transactionId))
                    }, null, 8, ["blockCardDetails", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["total-items", "data-source", "loadMore", "is-show-more-loading", "is-loading"])
      ]),
      _: 1
    })
  ]))
}