
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { BANCardDetails } from "@/shared/types/cards";
import { BlockOutlined } from "@ant-design/icons-vue";
import AmountText from "@/shared/components/typography/AmountText.vue";
import AdaCurrencySymbol from "@/shared/components/icons/AdaCurrencySymbol.vue";

export default defineComponent({
  props: {
    cardDetails: {
      type: Object as PropType<BANCardDetails>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    unitType: {
      type: String as PropType<"adaAmount" | "blocks">,
      default: "adaAmount",
    },
    minPrecision: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const t = useI18n().t;

    return {
      t,
    };
  },
  components: {
    BlockOutlined,
    AmountText,
    AdaCurrencySymbol,
  },
});
